import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

/**
 * --------------------------------------------------------------
 * just for local purpose
 * @project firebase addin-firebasee
 * --------------------------------------------------------------
 */
let firebaseConfig = {
  apiKey: "AIzaSyBAo6btsL5U-QPUI3K7d_TTcN7wtnPPxYk",
  authDomain: "livechat-demo-3e391.firebaseapp.com",
  databaseURL:
    "https://livechat-demo-3e391-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "livechat-demo-3e391",
  storageBucket: "livechat-demo-3e391.appspot.com",
  messagingSenderId: "410333544045",
  appId: "1:410333544045:web:d41b2913e9d0b0a47b4964",
};
firebase.initializeApp(firebaseConfig);

/**
 * --------------------------------------------------------------
 * init configures config
 * --------------------------------------------------------------
 */
const db = firebase.database();
const Auth = firebase.auth();

/**
 * --------------------------------------------------------------
 * export const
 * --------------------------------------------------------------
 */
export { db, Auth, firebase };
